import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PostCard from '../components/PostCard'

export const query = graphql`
  {
    posts: allStrapiPost(sort: { fields: created_on, order: DESC }) {
      nodes {
        title
        slug
        excerpt
        created_on
        author {
          name
          username
        }
        tags {
          title
          slug
        }
      }
    }
  }
`

const Home = ({ data }) => {
  return (
    <Layout>
      <SEO />
      {data &&
        data.posts.nodes.map(post => <PostCard key={post.slug} post={post} />)}
    </Layout>
  )
}

export default Home
